import * as React from 'react';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

export default function StickyTable({ ...props }) {
  return (
    <div>
      <Typography level="body-sm" textAlign="center" sx={{ mb: 2 }}>
        {props.title}
      </Typography>
      <Sheet sx={{ height: 'auto', overflow: 'auto' }}>
        <Table
          aria-label="table with sticky header"
          stickyHeader
          stickyFooter
          stripe="odd"
          hoverRow
          sx={{ minWidth: 10 }}
        >
          <thead>
            <tr>
              <th>{props.header1}</th>
              <th>{props.header2}</th>
            </tr>
          </thead>
          <tbody>
            {/* {rows.map((row) => (
              <tr key={row.name}>
                <td>{row.name}</td>
                <td>{row.calories}</td>
                <td>{row.fat}</td>
                <td>{row.carbs}</td>
                <td>{row.protein}</td>
              </tr>
            ))} */}
            {props.rows.map((row) => (
              <tr
                key={row.subject}
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                <td>{row.subject}</td>
                <td>{row.action.join(', ')}</td>
              </tr>
            ))}
            {/* {console.log(props.rows)} */}
          </tbody>
          {/* <tfoot>
            <tr>
              <th scope="row">Totals</th>
              <td>{sum('calories').toFixed(2)}</td>
              <td>{sum('fat').toFixed(2)}</td>
              <td>{sum('carbs').toFixed(2)}</td>
              <td>{sum('protein').toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {sum('calories') + sum('fat') + sum('carbs') + sum('protein')}{' '}
                Kcal
              </td>
            </tr>
          </tfoot> */}
        </Table>
      </Sheet>
    </div>
  );
}

// import "bootstrap/dist/css/bootstrap.min.css";
// import { CssBaseline } from '@mui/material';
// import MiniDrawer from '../component/Drawer';
// import "./App.css";

// function App() {
// 	return (
// 		<>
//       <div className="App">
//         <MiniDrawer />
//       </div>
//       <CssBaseline />
//     </>
// 	);
// }

// export default App;

import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import MiniDrawer from '../component/Header';
import Login from '../auth/login';
import Register from '../auth/register';
import ProtectedRoutes from '../utils/protectedRoutes';
import './App.css';

function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/*" element={<MiniDrawer />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;

import React, { useEffect } from 'react';
import {
  Box,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
const EditUser = ({
  id,
  validate,
  handleClose,
  setEditId,
  abilityList,
  firstName,
  lastName,
  email,
  code,
  phono,
  role,
  rolesname,
  setRolesName,
  checked,
  setFirstName,
  setLastName,
  setEmail,
  setCode,
  setPhoNo,
  setRole,
  setChecked,
  handleChange,
  handleSave,
  errors,
}) => {
  const handleSubmit = () => {
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(firstName, lastName, email, code, phono, role, checked)
        .length !== 0
    )
      return handleSave(
        id,
        firstName,
        lastName,
        email,
        code,
        phono,
        role,
        checked
      );
    else return;
  };

  const handleRoleId = () => {
    const filteredRoles = abilityList.filter((user) => user._id === role);
    if (filteredRoles.length > 0) {
      const currentRoleName = filteredRoles[0].roleName;
      setRolesName(currentRoleName);
    } else {
      setRolesName('');
    }
  };
  useEffect(() => {
    handleRoleId();
  });
  const handleChanges = (event) => {
    const selectedRoleName = event.target.value;
    const selectedRole = abilityList.find(
      (role) => role.roleName === selectedRoleName
    );
    console.log('SELECTRO', selectedRole);
    setRolesName(selectedRoleName);
    setRole(selectedRole ? selectedRole._id : '');
  };
  const handleCancel = () => {
    handleClose();
    setEditId('');
  };
  return (
    <React.Fragment>
      <DialogTitle className="dialog-header">
        <p className="dialog-header-text">Edit User</p>
        <CloseIcon onClick={handleCancel} className="dialog-closeicon" />
      </DialogTitle>
      <DialogContent>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="10px">
          <Box gridColumn="span 6" margin="8px">
            <TextField
              fullWidth
              label="First Name"
              type="text"
              autoComplete="off"
              name="firstName"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                validate(e, 'firstName', e.target.value);
              }}
              size="small"
            />
            {errors.firstname && <h5>{errors.firstname}</h5>}
          </Box>
          <Box gridColumn="span 6" margin="8px">
            <TextField
              fullWidth
              label="Last Name"
              onChange={(e) => {
                setLastName(e.target.value);
                validate(e, 'lastName', e.target.value);
              }}
              name="lastName"
              value={lastName}
              autoComplete="off"
              size="small"
            />
            {errors.lastname && <h5>{errors.lastname}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <FormControl fullWidth size="small">
              <InputLabel>code</InputLabel>
              <Select
                size="small"
                label="Code"
                required
                labelId="demo-select-small"
                id="demo-select-small"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  setPhoNo('');
                  validate(e, 'code', e.target.value);
                }}
                name="code"
              >
                <MenuItem value="+1">+1</MenuItem>
                <MenuItem value="+91">+91</MenuItem>
              </Select>
            </FormControl>
            {errors.codes && <h5>{errors.codes}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <TextField
              type="tel"
              format="(###) ###-####"
              mask="_"
              size="small"
              autoComplete="off"
              label="Contact Number"
              maxLength="10"
              onChange={handleChange}
              value={phono}
              name="phono"
            />
            {errors.contact && <h5>{errors.contact}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <TextField
              size="small"
              label="Email"
              autoComplete="off"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validate(e, 'email', e.target.value);
              }}
              name="email"
            />
            {errors.emailcheck && <h5>{errors.emailcheck}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                labelId="demo-select-small"
                id="demo-select-small"
                size="small"
                value={rolesname}
                name="role"
                onChange={handleChanges}
              >
                {abilityList.map((name) => (
                  <MenuItem key={name.roleName} value={name.roleName}>
                    {name.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.roles && <h5>{errors.roles}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <Switch
              checked={checked}
              name="checked"
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {checked ? 'Active' : 'InActive'}
          </Box>
        </Box>

        <DialogActions>
          <Link component="button" variant="body2" onClick={handleCancel}>
            Cancel
          </Link>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ textTransform: 'none' }}
            className="submit-button"
            onClick={(e) =>
              handleSubmit(
                firstName,
                lastName,
                email,
                code,
                phono,
                role,
                checked
              )
            }
            disabled={
              !firstName || !lastName || !email || !code || !phono || !role
            }
          >
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </React.Fragment>
  );
};
export default EditUser;

import React, { Fragment, useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, TextField, Dialog, Button, InputAdornment } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import SnackBar from '../../../helpers/SnackBar';
import DeleteUser from '../../../helpers/DeleteHelpers';
import UserValidation from './UserValidation';
import Axios from 'axios';
import '../../../style/Setting.css';
import AddIcon from '@mui/icons-material/Add';
import ClockLoader from 'react-spinners/ClockLoader';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router-dom';

const UserList = ({ drawer }) => {
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',
      // Authorization: 'Bearer' + ' ' + `${localStorage.getItem('token')}`,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [snackDelete, setSnackDelete] = useState(false);
  const [snackAdd, setSnackAdd] = useState(false);
  const [snackUpdate, setSnackUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  //Open popup
  const handleClickOpen = () => {
    setOpen(true);
    setAdd(true);
  };
  //Close popup
  const handleClose = () => {
    setOpen(false);
    setAdd(false);
    setDeletes(false);
  };

  const UsersData = [];
  const [listall, setListAll] = useState([]);
  const [error, setError] = useState(null);
  //list all the user
  const [abilityList, setAbilityList] = useState([]);

  const fetchData = async (
    API_URL,
    AuthHeaderRef,
    setListAll,
    setLoading,
    navigate,
    setAbilityList
  ) => {
    const delay = 2000;

    // Use setTimeout to hide the CircularProgress after the delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    try {
      const localAuthHeader = AuthHeaderRef.current;
      await Axios.get(`${API_URL}/users`, localAuthHeader).then((res) => {
        console.log('Successully getting data', res.data.result);
        setListAll(res.data.result);
      });
      await Axios.get(`${API_URL}/permissions`, localAuthHeader).then((res) => {
        console.info('Successully getting data');
        console.log('Data received', res.data.result);
        setAbilityList(res.data.result);
      });
    } catch (err) {
      console.log(err);
    }
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    fetchData(
      API_URL,
      AuthHeaderRef,
      setListAll,
      setLoading,
      navigate,
      setAbilityList
    );
  }, [API_URL, navigate]);

  const [users, setUsers] = useState(UsersData);
  const [searchTerm, setSearchTerm] = useState('');
  const initialFormState = {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    code: '',
    phone_number: '',
    role_name: '',
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [deletes, setDeletes] = useState(false);
  const [deleteId, setDeleteId] = useState(1);
  const [editId, setEditId] = useState(null);
  const handleEditClick = (event, user) => {
    event.preventDefault();
    setOpen(true);
    setEditId(user._id);
    setCurrentUser({
      id: user._id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      code: user.countryCode,
      phone_number: user.phoneNumber,
      role_name: user.roleId,
      checked: user.status,
    });
  };
  const handleDelete = (id) => {
    setOpen(true);
    setDeletes(true);
    setDeleteId(id);
  };
  const handleEvent = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleReset = () => {
    setSearchTerm('');
  };

  /**
   * Deletes the specified item from the collection.
   */

  const handleDeletes = async (deleteId) => {
    try {
      await Axios.delete(
        `${API_URL}/users/` + deleteId,
        AuthHeaderRef.current
      ).then(({ data }) => {
        console.log(data);
      });
    } catch (error) {
      setError('Failed to delete data');
    }
    handleClose();
    setOpen(false);
    setDeletes(false);
    setEditId(null);
    setSnackDelete(true);
  };

  /**
   * Adds a new item to the collection.
   */

  function addUser(
    firstName,
    lastName,
    email,
    code,
    phono,
    password,
    role,
    checked
  ) {
    console.log('Enter adduser');
    const handleInsert = {
      firstName: firstName,
      lastName: lastName,
      userName: firstName + lastName,
      email: email,
      password: password,
      countryCode: code,
      phoneNumber: phono,
      roleId: role,
      status: checked,
    };
    console.log('HI', handleInsert);
    try {
      Axios.post(`${API_URL}/users`, handleInsert, AuthHeaderRef.current).then(
        (res) => console.log('Successfully Inserted the User')
      );
    } catch (err) {
      console.log('ERROR', err);
    }
    setSnackAdd(true);
  }

  /**
   * Update a new item to the collection.
   */

  function handleUpdate(
    id,
    firstName,
    lastName,
    email,
    code,
    phono,
    role,
    checked
  ) {
    const handleUpdate = {
      _id: id,
      firstName: firstName,
      lastName: lastName,
      userName: firstName + lastName,
      email: email,
      countryCode: code,
      phoneNumber: phono,
      roleId: role,
      status: checked,
    };
    try {
      const res = Axios.patch(
        `${API_URL}/users/` + id,
        handleUpdate,
        AuthHeaderRef.current
      );
      console.log('successfully updated the User', res);
    } catch (err) {
      console.log('ERROR', err);
    }
    setEditId(null);
    handleClose();
    setSnackUpdate(true);
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleSnackbarClose = () => {
    setSnackDelete(false);
    setSnackUpdate(false);
    setSnackAdd(false);
    // Refresh the table data here
    setLoading(true); // Set loading state to true to show spinner
    fetchData(
      API_URL,
      AuthHeaderRef,
      setListAll,
      setLoading,
      navigate,
      setAbilityList
    ); // Fetch data again to refresh the table
  };
  return (
    <>
      {snackDelete ? (
        <SnackBar
          title="User deleted successfully"
          severity="error"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackAdd ? (
        <SnackBar
          title="User Added successfully"
          severity="success"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackUpdate ? (
        <SnackBar
          title="User Updated successfully"
          severity="info"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        id="grid-margin"
      >
        <Box gridColumn="span 2" gridRow="span 3">
          <TextField
            // label="Search..."
            id="outlined-start-adornment"
            size="small"
            onChange={handleEvent}
            placeholder="Search..."
            className="textfield-search-label"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
        <Box gridColumn="span 2" gridRow="span 3">
          {drawer ? (
            <Button
              className="button"
              id="reset-button-drawer"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          ) : (
            <Button
              className="button"
              id="reset-button"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          )}
        </Box>
        <Box gridColumn="span 8" gridRow="span 3">
          {drawer ? (
            <Button
              id="add-button-drawer"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          ) : (
            <Button
              id="add-button"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        {deletes ? (
          <DeleteUser
            title="Delete"
            content="Are you sure to delete?"
            setOpen={setOpen}
            setDeletes={setDeletes}
            setUsers={setUsers}
            users={users}
            deleteId={deleteId}
            handleDeletes={handleDeletes}
            handleClose={handleClose}
          />
        ) : null}
        {add ? (
          <UserValidation
            addUser={addUser}
            handleClose={handleClose}
            abilityList={abilityList}
          />
        ) : null}
        {editId ? (
          <UserValidation
            currentUser={currentUser}
            abilityList={abilityList}
            setUsers={setUsers}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            setEditId={setEditId}
            users={users}
            editId={editId}
          />
        ) : null}
      </Dialog>
      {/* TABLE */}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <TableContainer component={Paper} id="table-container">
          <Table id="table" size="small" aria-label="a dense table">
            <TableHead id="table-header">
              <TableRow>
                <TableCell id="table-header-align">First Name</TableCell>
                <TableCell id="table-header-align">Last Name</TableCell>
                <TableCell id="table-header-align">Email</TableCell>
                <TableCell id="table-header-align">Code</TableCell>
                <TableCell id="table-header-align">Contact Number</TableCell>
                <TableCell id="table-header-align">Roles</TableCell>
                <TableCell id="table-header-align">Status</TableCell>
                <TableCell id="table-header-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listall.length > 0 ? (
                listall
                  .filter(
                    (user) =>
                      user.firstName &&
                      user.firstName.toLowerCase().includes(searchTerm)
                  )
                  .map((user) => (
                    <Fragment>
                      <TableRow key={user._id}>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.countryCode}</TableCell>
                        <TableCell>{user.phoneNumber}</TableCell>
                        {abilityList.map((name) => (
                          <Fragment key={name._id}>
                            {name._id === user.roleId ? (
                              <TableCell>{name.roleName}</TableCell>
                            ) : null}
                          </Fragment>
                        ))}
                        {user.status === true ? (
                          <TableCell>Active</TableCell>
                        ) : (
                          <TableCell>InActive</TableCell>
                        )}
                        <TableCell>
                          <EditIcon
                            id="edit-button"
                            onClick={(event) => handleEditClick(event, user)}
                          />
                          <DeleteIcon
                            id="delete-button"
                            onClick={() => handleDelete(user._id)}
                          />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
              ) : (
                <TableRow>
                  <td colSpan={7}>No Users</td>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default UserList;

import React, { useState } from "react";
import { omit } from "lodash";
import EditForm from "./EditVendors";
function VendorsValidation(props) {
  const id =
    props.currentUser && props.currentUser.id
      ? props.currentUser.id
      : undefined;
  console.log("ID", id);
  const [businessName, setBusinessName] = useState(id ? props.currentUser.BusinessName : "");
  const [email, setEmail] = useState(id ? props.currentUser.email : "");
  const [phono, setPhono] = useState(id ? props.currentUser.phono : "");
  const [address, setAddress] = useState(id ? props.currentUser.address : "");
  const [city, setCity] = useState(id ? props.currentUser.city : "");
  const [state, setState] = useState(id ? props.currentUser.state : "");
  const [zip, setZip] = useState(id ? props.currentUser.zip : "");
  const [businesstype, setBusinessType] = useState(id ? props.currentUser.businesstype : "");
  const [serviceoffered, setServiceOffered] = useState(id ? props.currentUser.serviceoffered : "");
  const [website, setWebsite] = useState(id ? props.currentUser.website : "");
  const [contactPersonName, setContactPersonName] = useState(id ? props.currentUser.contactPersonName : "");
  const [startTime, setStartTime] = useState(id ? props.currentUser.startTime : "");
  const [endTime, setEndTime] = useState(id ? props.currentUser.endTime : "");
  const [licenseType, setLicenseType] = useState(id ? props.currentUser.licenseType : "");
  const [licenseNumber, setLicenseNumber] = useState(id ? props.currentUser.licenseNumber : "");
  const [yearsInBusiness, setYearsInBusiness] = useState(id ? props.currentUser.yearsInBusiness : "");
  const [filesPath, setFilesPath] = useState(id ? props.currentUser.filesPath : []);
  console.log("FILESPATH",filesPath);

  /**
   * check validations.
   */

  const companyname = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        companyname: "The field is required",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "companyname");
      setErrors(newObj);
    }
  };
  const phone = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        phone: "The field is required",
      });
    } else if (value.length >= 15) {
      setErrors({
        ...errors,
        phone: "PhoneNumber must give 10 digit only",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "phone");
      setErrors(newObj);
    }
  };
  const addres = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        addres: "The field is required",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "addres");
      setErrors(newObj);
    }
  };
  const citty = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        citty: "The field is required",
      });
    } else if (!/^[A-Za-z]+$/i.test(value)) {
      setErrors({
        ...errors,
        citty: "Give alphabatic only",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "citty");
      setErrors(newObj);
    }
  };
  const stat = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        stat: "The field is required",
      });
    } else if (!/^[A-Za-z]+$/i.test(value)) {
      setErrors({
        ...errors,
        stat: "Give alphabatic only",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "stat");
      setErrors(newObj);
    }
  };
  const zipcode = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        zipcode: "The field is required",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "zipcode");
      setErrors(newObj);
    }
  };
  const btype = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        btype: "The field is required",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "btype");
      setErrors(newObj);
    }
  };
  const serviceoffer = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        serviceoffer: "The field is required",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "serviceoffer");
      setErrors(newObj);
    }
  };
  const gmail = (name, value) => {
    if (
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value)
    ) {
      setErrors({
        ...errors,
        gmail: "Enter a valid email address",
      });
    } else {
      let newObj = omit(errors, "gmail");
      setErrors(newObj);
    }
  };
  const web = (name, value) => {
    if (
      !new RegExp(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      ).test(value)
    ) {
      setErrors({
        ...errors,
        web: "Enter a Valid Website",
      });
    } else {
      let newObj = omit(errors, "web");
      setErrors(newObj);
    }
  };
  const contactperson = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        contactperson: "The field is required",
      });
    } else if (!/^[A-Za-z]+$/i.test(value)) {
      setErrors({
        ...errors,
        contactperson: "Give alphabatic only",
      });
    } else if (value.length >= 30) {
      setErrors({
        ...errors,
        contactperson: "name cannot exceed 30 characters",
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, "contactperson");
      setErrors(newObj);
    }
  };
  const [errors, setErrors] = useState({});

  /**
   * A function to validate each input values
   */

  const validate = (event, name, value) => {
    switch (name) {
      case "businessName":
        companyname(name, value);
        break;
      case "phono":
        phone(name, value);
        break;
      case "email":
        gmail(name, value);
        break;
      case "website":
        web(name, value);
        break;
      case "address":
        addres(name, value);
        break;
      case "city":
        citty(name, value);
        break;
      case "state":
        stat(name, value);
        break;
        case "contactPersonName":
        contactperson(name, value);
        break;
      case "zip":
        zipcode(name, value);
        break;
      case "businesstype":
        btype(name, value);
        break;
      case "serviceoffered":
        serviceoffer(name, value);
        break;
      default:
        break;
    }
  };

  /**
   * It will passes the value to update function.
   */

  const handleUpdateForm = () => {
    props.handleUpdate(
      id,
      businessName,
      email,
      phono,
      address,
      city,
      state,
      zip,
      businesstype,
      serviceoffered,
      website,
      contactPersonName,
      startTime,
      endTime,
      licenseType,
      licenseNumber,
      yearsInBusiness,
      filesPath
    );
    props.handleClose();
  };

  /**
   * A function to track phonenumber input values.
   */

  const handleChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhono(formattedPhoneNumber);
    const { name, value } = event.target;
    //event.persist();
    validate(event, name, value);
    //setUser({ ...user, [name]: value });
  };

  /**
   * A function to Load input files.
   */

  const handleFileChange = (event) => {
    setFilesPath([...filesPath, ...event.target.files]);
  };
  return (
    <div>
      {id ? (
        <EditForm
          id={id}
          businessName={businessName}
          setBusinessName={setBusinessName}
          email={email}
          setEmail={setEmail}
          phono={phono}
          setPhono={setPhono}
          handleChange={handleChange}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          businesstype={businesstype}
          setBusinessType={setBusinessType}
          serviceoffered={serviceoffered}
          setServiceOffered={setServiceOffered}
          website={website}
          setWebsite={setWebsite}
          contactPersonName={contactPersonName}
          setContactPersonName={setContactPersonName}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          licenseNumber={licenseNumber}
          setLicenseNumber={setLicenseNumber}
          yearsInBusiness={yearsInBusiness}
          setYearsInBusiness={setYearsInBusiness}
          handleUpdateForm={handleUpdateForm}
          errors={errors}
          handleFileChange={handleFileChange}
          handleClose={props.handleClose}
          validate={validate}
          filesPath={filesPath}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default VendorsValidation;

/**
   * A function to convert American phonenumber format.
   */

function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;
  //if (phoneNumberLength >= 11) return null;
  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 11)}`;
}

import React from 'react';
import { TextField } from '@mui/material';
import { TableCell, TableRow, Button } from '@mui/material';
import Switch from '@mui/material/Switch';
import '../../style/Pages.css';

function EditSubscription({
  id,
  handleUpdates,
  errors,
  validate,
  name,
  period,
  // startDate,
  // endDate,
  amount,
  setFeature,
  feature,
  setChecked,
  checked,
}) {
  /**
   * It will passes the current value to the update function.
   */

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0 && Object.keys(feature).length !== 0)
      return handleUpdates(
        id,
        name,
        period,
        // startDate,
        // endDate,
        feature,
        amount,
        checked
      );
  };
  return (
    <>
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>{period}</TableCell>
        {/* <TableCell>{startDate}</TableCell> */}
        {/* <TableCell>{endDate}</TableCell> */}
        <TableCell>
          <TextField
            label="Feature"
            type="text"
            autoComplete="off"
            name="feature"
            value={feature}
            onChange={(e) => {
              setFeature(e.target.value);
              validate(e, 'feature', e.target.value);
            }}
            size="small"
          />
          {errors.features && <h6>{errors.features}</h6>}
        </TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>
          <Switch
            checked={checked}
            name="checked"
            onChange={(e) => setChecked(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          {checked ? 'Active' : 'Expired'}
        </TableCell>
        <TableCell>
          <Button className="myButton variantContained" onClick={handleSubmit}>
            Save
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default EditSubscription;

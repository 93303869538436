import React, { Fragment, useState, useEffect } from 'react';
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { Dialog } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBar from '../../helpers/SnackBar';
import '../../style/Pages.css';
import '../../style/Setting.css';
import Axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import ClockLoader from 'react-spinners/ClockLoader';
import Validation from './SubscriptionValidation';
import DeleteUser from '../../helpers/DeleteHelpers';
import '../../style/setting_tab.css';
import { useNavigate } from 'react-router-dom';
// import dotenv from 'dotenv';
// dotenv.config({ path: '../../.env.dev' });
const SubscriptionList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackUpdate, setSnackUpdate] = useState(false);
  const [snackDelete, setSnackDelete] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deletes, setDeletes] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteId, setDeleteId] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  /**
   * Opens a new popup with the specified content.
   */

  const handleClickOpen = () => {
    setOpen(true);
    setAdd(true);
  };

  /**
   * Closes the currently open popup.
   */

  const handleClose = () => {
    setOpen(false);
    setAdd(false);
  };
  const [users, setUsers] = useState([]);
  console.log('USERS', users);
  console.log('CHECK TYPE', typeof users);
  // const apiUrl = process.env.REACT_APP_API_URL;

  /**
   * Retrieves data from a data source.
   */

  const fetchData = async (API_URL, setUsers, setLoading, navigate) => {
    const delay = 2000;

    // Use setTimeout to hide the CircularProgress after the delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);
    try {
      await Axios.get(`${API_URL}/subscription`).then((res) => {
        console.log('Getting axios data', res.data);
        setUsers(res.data.result);
      });
    } catch (error) {
      // setError('Failed to fetch data');
      navigate('/error');
    }
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    fetchData(API_URL, setUsers, setLoading, navigate);
  }, [navigate, API_URL]);

  const initialFormState = {
    id: null,
    subscription_name: '',
    subscription_period: '',
    // start_date: '',
    // end_date: '',
    feature: '',
    amount_due: '',
    checked: '',
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  console.log('CU', currentUser);
  /**
   * Adds a new item to the collection.
   */

  const handleInsert = async (
    name,
    period,
    // startDate,
    // endDate,
    feature,
    amount,
    checked
  ) => {
    try {
      await Axios.post(`${API_URL}/subscription`, {
        subscription_name: name,
        subscription_period: period,
        // start_date: startDate,
        // end_date: endDate,
        feature: feature,
        amount_due: parseInt(amount, 10),
        checked: checked,
      }).then((res) => console.log('Posting Data', res));
    } catch (error) {
      setError('Failed to insert data');
    }
    handleClose();
    setAdd(false);
    setSnack(true);
  };

  function formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Extract the numeric value from the $numberDecimal object
    const numericAmount = parseFloat(amount.$numberDecimal);

    return formatter.format(numericAmount);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  /**
   * Edits the specified item with the provided changes.
   */

  const handleEditClick = (event, user) => {
    event.preventDefault();
    setEditId(user._id);
    setCurrentUser({
      id: user._id,
      subscription_name: user.subscription_name,
      subscription_period: user.subscription_period,
      // start_date: formatDate(user.start_date),
      // end_date: formatDate(user.end_date),
      amount_due: formatAmount(user.amount_due),
      feature: user.feature,
      checked: user.checked,
    });
  };

  /**
   * Updates the specified data with the provided changes.
   */

  const handleUpdate = async (
    id,
    name,
    period,
    // startDate,
    // endDate,
    feature,
    amount,
    checked
  ) => {
    const handleUpdates = {
      id: id,
      subscription_name: name,
      subscription_period: period,
      // start_date: startDate,
      // end_date: endDate,
      feature: feature,
      amount_due: parseInt(amount, 10),
      checked: checked,
    };
    try {
      await Axios.put(`${API_URL}/subscription/` + id, handleUpdates).then(
        ({ data }) => {
          console.log(data);
        }
      );
    } catch (error) {
      setError('Failed to update data');
    }
    setEditId(null);
    setSnackUpdate(true);
  };

  /**
   * Displays a confirmation popup to confirm deletion of an item.
   */

  const handleDelete = (id) => {
    setOpen(true);
    setDeletes(true);
    setDeleteId(id);
  };

  /**
   * Deletes the specified item from the collection.
   */

  const handleDeletes = async (deleteId) => {
    try {
      await Axios.delete(`${API_URL}/subscription/` + deleteId).then(
        ({ data }) => {
          console.log(data);
        }
      );
    } catch (error) {
      setError('Failed to delete data');
    }
    handleClose();
    setOpen(false);
    setDeletes(false);
    setSnackDelete(true);
  };

  /**
   * Render error fallback UI.
   */

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleSnackbarClose = () => {
    setSnackDelete(false);
    setSnackUpdate(false);
    setSnack(false);
    // Refresh the table data here
    setLoading(true); // Set loading state to true to show spinner
    fetchData(API_URL, setUsers, setLoading, navigate); // Fetch data again to refresh the table
  };

  return (
    <>
      {snack ? (
        <SnackBar
          title="Subscription created successfully"
          severity="success"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackUpdate ? (
        <SnackBar
          title="Subscription Updated successfully"
          severity="info"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackDelete ? (
        <SnackBar
          title="Subscription deleted successfully"
          severity="error"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      <div className="grid-container">
        <div className="tab_font_style_subscription">Subscription</div>
        <div></div>
        <div></div>
        <div>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Add
          </Button>
          {/* <button
            variant="contained"
            onClick={handleClickOpen}
            className="addbutton"
          >
            Add
          </button> */}
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        {add ? (
          <Validation handleClose={handleClose} handleInsert={handleInsert} />
        ) : (
          <></>
        )}
        {deletes ? (
          <DeleteUser
            title="Delete"
            content="Are you sure to delete?"
            setOpen={setOpen}
            setDeletes={setDeletes}
            setUsers={setUsers}
            users={users}
            deleteId={deleteId}
            handleDeletes={handleDeletes}
            handleClose={handleClose}
          />
        ) : (
          <></>
        )}
      </Dialog>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <TableContainer component={Paper} id="table-container">
          <Table id="table" size="small" aria-label="a dense table">
            <TableHead id="table-header">
              <TableRow>
                <TableCell id="table-header-align">Subscription</TableCell>
                <TableCell id="table-header-align">Period</TableCell>
                {/* <TableCell id="table-header-align">Start Date</TableCell> */}
                {/* <TableCell id="table-header-align">End Date</TableCell> */}
                <TableCell id="table-header-align">Feature</TableCell>
                <TableCell id="table-header-align">Amount Due</TableCell>
                <TableCell id="table-header-align">Status</TableCell>
                <TableCell id="table-header-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 ? (
                users.map((user) => (
                  <Fragment>
                    {editId === user._id ? (
                      <Validation
                        currentUser={currentUser}
                        handleUpdate={handleUpdate}
                      />
                    ) : (
                      <TableRow key={user._id}>
                        <TableCell>{user.subscription_name}</TableCell>
                        <TableCell>{user.subscription_period}</TableCell>
                        {/* <TableCell>{formatDate(user.start_date)}</TableCell> */}
                        {/* <TableCell>{formatDate(user.end_date)}</TableCell> */}
                        <TableCell>{user.feature}</TableCell>
                        <TableCell>{formatAmount(user.amount_due)}</TableCell>
                        {user.checked === true ? (
                          <TableCell>Active</TableCell>
                        ) : (
                          <TableCell>Expired</TableCell>
                        )}
                        <TableCell>
                          <Tooltip title="Edit">
                            <EditIcon
                              className="editicon"
                              size="small"
                              onClick={(event) => handleEditClick(event, user)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              className="deleteicon"
                              size="small"
                              onClick={() => handleDelete(user._id)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Users</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default SubscriptionList;

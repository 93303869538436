import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import DrawerSideBar from './DrawerSideBar';
import Menu from './Menu';

export default function HeaderBar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(70);
  const [drawerLocked, setDrawerLocked] = React.useState(false);
  const [lock, setLock] = React.useState(false);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Menu open={open} setOpen={setOpen} />
        </Toolbar>
      </AppBar>
      <DrawerSideBar
        theme={theme}
        setOpen={setOpen}
        open={open}
        drawerWidth={drawerWidth}
        setDrawerWidth={setDrawerWidth}
        drawerLocked={drawerLocked}
        setDrawerLocked={setDrawerLocked}
        lock={lock}
        setLock={setLock}
      />
    </>
  );
}

import React from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import debounce from 'lodash/debounce';
import LogoutDialog from '../auth/logout';
import { Box, Stack } from '@mui/material';
import '../style/Profile.css';

export default function MenuList({ open, setOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logout, setLogout] = React.useState(false);
  const openaccount = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuButtonRef = React.useRef(null);
  const [isLogout, setIsLogout] = React.useState(false);

  const handleDrawerOpen = (event) => {
    if (event.target === menuButtonRef.current) {
      setOpen(true);
    }
  };

  const handleLogout = () => {
    setIsLogout(true);
    setLogout(true);
  };

  return (
    <>
      {isLogout && (
        <LogoutDialog
          logout={logout}
          setLogout={setLogout}
          setIsLogout={setIsLogout}
        />
      )}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onMouseEnter={handleDrawerOpen}
        ref={menuButtonRef}
        edge="start"
        sx={{ marginRight: 5, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" width={'100%'}>
        <Box gridColumn="span 8"> </Box>
        <Box gridColumn="span 2"></Box>
        <Box gridColumn="span 2" className="icon-align">
          <Stack direction="row" spacing={1}>
            <div className="dropdown">
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openaccount ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openaccount ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                </IconButton>
              </Tooltip>
              <div className="admin-text">Admin</div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openaccount}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {/* <MenuItem onClick={handleClose}>
                  <Avatar /> Admin
                </MenuItem> */}
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

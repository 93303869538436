import React, { useState } from 'react';
import AddUser from './AddUser';
import Edit from './EditUser';
import { omit } from 'lodash';

const UserValidation = (props) => {
  console.log('editid', props.setEditId);
  const id =
    props.currentUser && props.currentUser.id
      ? props.currentUser.id
      : undefined;
  console.log('id', id);
  const [firstName, setFirstName] = useState(
    id ? props.currentUser.first_name : ''
  );
  const [lastName, setLastName] = useState(
    id ? props.currentUser.last_name : ''
  );
  const [email, setEmail] = useState(id ? props.currentUser.email : '');
  const [code, setCode] = useState(id ? props.currentUser.code : '');
  const [phono, setPhoNo] = useState(id ? props.currentUser.phone_number : '');
  const [role, setRole] = useState(id ? props.currentUser.role_name : '');
  console.log('ROLE', role);
  const [rolesname, setRolesName] = useState('');
  console.log('rolesname', rolesname);
  const [checked, setChecked] = useState(id ? props.currentUser.checked : true);
  const [password, setPassword] = useState('');

  const firstname = (name, value) => {
    if (value.length === 0) {
      setErrors({
        ...errors,
        firstname: 'The field is required',
      });
    } else if (!/^[A-Za-z]+$/i.test(value)) {
      setErrors({
        ...errors,
        firstname: 'Give alphabatic only',
      });
    } else if (value.length >= 30) {
      setErrors({
        ...errors,
        firstname: 'Name cannot exceed 30 characters',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'firstname');
      setErrors(newObj);
    }
  };
  const lastname = (name, value) => {
    if (value.length === 0) {
      // we will set the error state
      setErrors({
        ...errors,
        lastname: 'The field is required',
      });
    } else if (!/^[A-Za-z]+$/i.test(value)) {
      setErrors({
        ...errors,
        lastname: 'Give alphabatic only',
      });
    } else if (value.length >= 30) {
      setErrors({
        ...errors,
        lastname: 'Name cannot exceed 30 characters',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'lastname');
      setErrors(newObj);
    }
  };
  const emailcheck = (name, value) => {
    if (
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value)
    ) {
      setErrors({
        ...errors,
        emailcheck: 'Enter a valid email address',
      });
    } else {
      let newObj = omit(errors, 'emailcheck');
      setErrors(newObj);
    }
  };
  const codes = (name, value) => {
    if (value.length === 0) {
      // we will set the error state
      setErrors({
        ...errors,
        codes: 'The field is required',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'codes');
      setErrors(newObj);
    }
  };
  const contact = (name, value) => {
    if (value.length === 0) {
      // we will set the error state
      setErrors({
        ...errors,
        contact: 'The field is required',
      });
    } else if (codes === '+1' && value.length >= 15) {
      setErrors({
        ...errors,
        contact: 'PhoneNumber must give 10 digit only',
      });
    } else if (codes === '+91' && value.length >= 13) {
      setErrors({
        ...errors,
        contact: 'PhoneNumber must give 10 digit only',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'contact');
      setErrors(newObj);
    }
  };
  const roles = (name, value) => {
    if (value.length === 0) {
      // we will set the error state
      setErrors({
        ...errors,
        roles: 'The field is required',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'roles');
      setErrors(newObj);
    }
  };
  const handleForm = () => {
    console.log('Enter handle form');
    props.addUser(
      firstName,
      lastName,
      email,
      code,
      phono,
      password,
      role,
      checked
    );
    props.handleClose();
  };
  const [errors, setErrors] = useState({});
  const validate = (event, name, value) => {
    switch (name) {
      case 'firstName':
        firstname(name, value);
        break;
      case 'lastName':
        lastname(name, value);
        break;
      case 'email':
        emailcheck(name, value);
        break;
      case 'code':
        codes(name, value);
        break;
      case 'phono':
        contact(name, value);
        break;
      case 'role':
        roles(name, value);
        break;
      default:
        break;
    }
  };
  function handleSave(
    id,
    firstName,
    lastName,
    email,
    code,
    phono,
    role,
    checked
  ) {
    return props.handleUpdate(
      id,
      firstName,
      lastName,
      email,
      code,
      phono,
      role,
      checked
    );
  }
  const handleChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    const formattedPhNumber = formatPhNumber(event.target.value);
    if (code === '+1') {
      setPhoNo(formattedPhoneNumber);
    } else if (code === '+91') {
      setPhoNo(formattedPhNumber);
    } else {
      setPhoNo('');
    }
    const { name, value } = event.target;
    validate(event, name, value);
  };
  return (
    <>
      {props.setEditId ? (
        <Edit
          id={id}
          setFirstName={setFirstName}
          validate={validate}
          handleClose={props.handleClose}
          abilityList={props.abilityList}
          setEditId={props.setEditId}
          firstName={firstName}
          lastName={lastName}
          email={email}
          code={code}
          phono={phono}
          role={role}
          rolesname={rolesname}
          setRolesName={setRolesName}
          checked={checked}
          setLastName={setLastName}
          setEmail={setEmail}
          setCode={setCode}
          setPhoNo={setPhoNo}
          setRole={setRole}
          setChecked={setChecked}
          handleSave={handleSave}
          handleChange={handleChange}
          errors={errors}
        />
      ) : (
        <AddUser
          abilityList={props.abilityList}
          setFirstName={setFirstName}
          validate={validate}
          firstName={firstName}
          lastName={lastName}
          email={email}
          code={code}
          role={role}
          rolesname={rolesname}
          setRolesName={setRolesName}
          password={password}
          checked={checked}
          setLastName={setLastName}
          setEmail={setEmail}
          setCode={setCode}
          setRole={setRole}
          setChecked={setChecked}
          setPassword={setPassword}
          handleChange={handleChange}
          setPhoNo={setPhoNo}
          phono={phono}
          errors={errors}
          handleForm={handleForm}
          handleClose={props.handleClose}
        />
      )}
    </>
  );
};

export default UserValidation;

function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;
  //if (phoneNumberLength >= 11) return null;
  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

function formatPhNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 6) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  // if (phoneNumberLength < 10) {
  //   return `${phoneNumber.slice(0, 5)} ${phoneNumber.slice(6, 10)}`;
  // }

  //finally, if the phoneNumberLength is greater then seven, we add the last
  //bit of formatting and return it.
  return `${phoneNumber.slice(0, 5)}  ${phoneNumber.slice(5, 10)}`;
}

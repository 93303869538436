const SUBJECT = [
  // "all",
  "dashboard",
  "property",
  "maintenance",
  "lease",
  "tenant",
  "invoice",
  "expenses",
  "landlordpayment",
  "payment",
  "vendor",
  "report",
  "settings",
  "document",
  "vendorworkorder",
  "privatevendor",
];
const ABILITIES = [
  // "manage",
  "create",
  "update",
  "delete",
  "view",
  "quote",
  "readAll",
  "readById",
  "visible", //This ability is used to show/hide the menu item
  //Roles to be used in the application
  "tenant",
  "landlord",
  "publicvendor",
];

export { SUBJECT, ABILITIES };

import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../style/Pages.css';

/**
 * A custom wrapper component for `MuiAlert` that adds elevation, variant, and other props.
 */

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar({
  title,
  severity,
  vertical,
  horizontal,
  onClose,
}) {
  const [open, setOpen] = React.useState(true);

  /**
   * Close the Snackbar.
   */

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (onClose) {
      onClose(); // Call the onClose callback if provided
    }
  };
  console.log('Exeuted snack');
  return (
    <Stack spacing={2} className="snackbar">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity} className="snackbar">
          {title}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

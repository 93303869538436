import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderBar from './HeaderBar';

function Header() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderBar />
      </Box>
    </>
  );
}

export default Header;

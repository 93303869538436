// const SILVER = 'Silver';
// const GOLD = 'Gold';
// const PLATINUM = 'Platinum';

// const SILVER_AMOUNT = '10000';
// const GOLD_AMOUNT = '50000';
// const PLATINUM_AMOUNT = '100000';

const FREE = 'Free';
const STANDARD = 'Standard';
const PREMIUM = 'Premium';

const FREE_AMOUNT = 0;
const STANDARD_AMOUNT = 10;
const PREMIUM_AMOUNT = 20;

const DECIMAL = '.00';
// const DECIMAL = 0.0;

const INR_SYMBOL = '₹';
const USD_SYMBOL = '$';

const VALID_TIMING_7DAYS = 7;
const VALID_TIMING_30DAYS = 30;
const VALID_TIMING_90DAYS = 90;
const VALID_TIMING_365DAYS = 365;

const VALID_PERIOD_7DAYS = 'Trial - 7 days';
const VALID_PERIOD_30DAYS = 'Monthly - 30 days';
const VALID_PERIOD_90DAYS = 'Quarterly - 90 days';
const VALID_PERIOD_365DAYS = 'Year - 365 days';

const USER_ROLE = ['Admin', 'Tenant', 'Landlord'];

const RESOURCES = ['dashboard', 'payments', 'properties', 'vendors', 'reports'];

const DASHBOARD = ['/getcards', '/addcard'];
const PAYMENTS = ['/payments/listall', '/payments/insert'];
const PROPERTIES = [
  '/properties/addproperty',
  '/properties/updateproperty/:id',
  '/properties/listAllproperties',
  '/properties/deleteproperty/:id',
];
const VENDORS = [
  '/vendors/listall',
  'vendors/insert',
  'vendors/update/:id',
  'vendors/:id',
];
const REPORTS = ['reports/listall'];

export {
  //   SILVER,
  //   GOLD,
  //   PLATINUM,
  //   SILVER_AMOUNT,
  //   GOLD_AMOUNT,
  //   PLATINUM_AMOUNT,
  FREE,
  STANDARD,
  PREMIUM,
  FREE_AMOUNT,
  STANDARD_AMOUNT,
  PREMIUM_AMOUNT,
  DECIMAL,
  INR_SYMBOL,
  USD_SYMBOL,
  VALID_TIMING_7DAYS,
  VALID_TIMING_30DAYS,
  VALID_TIMING_90DAYS,
  VALID_TIMING_365DAYS,
  VALID_PERIOD_7DAYS,
  VALID_PERIOD_30DAYS,
  VALID_PERIOD_90DAYS,
  VALID_PERIOD_365DAYS,
  USER_ROLE,
  RESOURCES,
  DASHBOARD,
  PAYMENTS,
  PROPERTIES,
  VENDORS,
  REPORTS,
};

import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
function DeleteHelpers({
  setOpen,
  handleClose,
  setUsers,
  setDeletes,
  users,
  deleteId,
  title,
  content,
  handleDeletes,
}) {

  /**
   * It will passes the current id which data you want to delete to delete function.
   */

  const handleDelete = (deleteId) => {
    handleDeletes(deleteId);
  };

  /**
 * Handles the "cancel" action.
 */

  const handleCancel = () => {
    handleClose();
    setDeletes(false);
  };
  return (
    <>
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
      <div className="grid-container">
          <div>
        <button
          className="mydelete_Button"
          onClick={() => handleDelete(deleteId)}
        >
          Delete
        </button>
        </div>
        <div>
        <button
          className="mycancel_Button"
          onClick={handleCancel}
        >
          Cancel
        </button>
        </div>
        </div>
      </DialogActions>
    </>
  );
}

export default DeleteHelpers;

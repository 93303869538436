import React, { useState } from 'react';
import Form from './NewSubscription';
import EditForm from './EditSubscription';
import { omit } from 'lodash';
function SubscriptionValidation(props) {
  const nowDate = new Date();
  const UsFormatter = new Intl.DateTimeFormat('en-US');
  // const news = UsFormatter.format(nowDate);

  // Get year, month, and day components
  const year = nowDate.getFullYear();
  const month = String(nowDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(nowDate.getDate()).padStart(2, '0');

  // Create the formatted date string
  const formattedDate = `${year}-${month}-${day}`;

  console.log('NEWS', formattedDate);
  const id =
    props.currentUser && props.currentUser.id
      ? props.currentUser.id
      : undefined;
  const [name, setName] = useState(
    id ? props.currentUser.subscription_name : ''
  );
  const [period, setPeriod] = useState(
    id ? props.currentUser.subscription_period : ''
  );
  // const [startDate, setStartDate] = useState(
  //   id ? props.currentUser.start_date : formattedDate
  // );
  // const [endDate, setEndDate] = useState(id ? props.currentUser.end_date : '');
  const [feature, setFeature] = useState(id ? props.currentUser.feature : '');
  const [amount, setAmount] = useState(id ? props.currentUser.amount_due : '');
  const [checked, setChecked] = useState(id ? props.currentUser.checked : true);

  /**
   * check validations.
   */

  const subscription_type = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        subscription_type: 'The field is required',
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'subscription_type');
      setErrors(newObj);
    }
  };
  const periods = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        periods: 'The field is required',
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'periods');
      setErrors(newObj);
    }
  };
  const features = (name, value) => {
    if (value.length === 0) {
      // we will set the error state

      setErrors({
        ...errors,
        features: 'The field is required',
      });
    } else {
      // set the error state empty or remove the error for username input

      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'features');
      setErrors(newObj);
    }
  };
  const [errors, setErrors] = useState({});

  /**
   * A function to validate each input values
   */

  const validate = (event, name, value) => {
    switch (name) {
      case 'name':
        subscription_type(name, value);
        break;
      case 'period':
        periods(name, value);
        break;
      case 'feature':
        features(name, value);
        break;
      default:
        break;
    }
  };

  /**
   * It will passes the value to add function.
   */

  const handleAddForm = () => {
    props.handleInsert(
      name,
      period,
      // startDate,
      // endDate,
      feature,
      amount,
      checked
    );
    props.handleClose();
  };

  /**
   * It will passes the value to update function.
   */

  function handleUpdates() {
    props.handleUpdate(
      id,
      name,
      period,
      // startDate,
      // endDate,
      feature,
      amount,
      checked
    );
    console.log('CHECKED', checked);
  }
  return (
    <>
      {id ? (
        <EditForm
          id={id}
          setName={setName}
          name={name}
          setPeriod={setPeriod}
          period={period}
          // setStartDate={setStartDate}
          // startDate={startDate}
          // setEndDate={setEndDate}
          // endDate={endDate}
          setFeature={setFeature}
          feature={feature}
          setAmount={setAmount}
          amount={amount}
          setChecked={setChecked}
          checked={checked}
          validate={validate}
          errors={errors}
          handleUpdates={handleUpdates}
          handleClose={props.handleClose}
        />
      ) : (
        <Form
          setName={setName}
          name={name}
          setPeriod={setPeriod}
          period={period}
          // setStartDate={setStartDate}
          // startDate={startDate}
          // setEndDate={setEndDate}
          // endDate={endDate}
          setFeature={setFeature}
          feature={feature}
          setAmount={setAmount}
          amount={amount}
          setChecked={setChecked}
          checked={checked}
          validate={validate}
          errors={errors}
          handleAddForm={handleAddForm}
          handleClose={props.handleClose}
        />
      )}
    </>
  );
}

export default SubscriptionValidation;

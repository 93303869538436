// import * as React from 'react';
// import Chip from '@mui/joy/Chip';
// import Modal from '@mui/joy/Modal';
// import ModalClose from '@mui/joy/ModalClose';
// import ModalDialog from '@mui/joy/ModalDialog';
// import Typography from '@mui/joy/Typography';
// import TableViewIcon from '@mui/icons-material/TableView';

// export default function DialogModel({ ...props }) {
//   const [open, setOpen] = React.useState(false);
//   return (
//     <React.Fragment>
//       <Chip
//         variant="soft"
//         startDecorator={<TableViewIcon />}
//         onClick={() => setOpen(true)}
//       >
//         View Abilities
//       </Chip>
//       <Modal open={open} onClose={() => setOpen(false)}>
//         <ModalDialog
//           aria-labelledby="variant-modal-title"
//           aria-describedby="variant-modal-description"
//           variant="soft"
//         >
//           <div className="dialog-header">
//             <ModalClose />
//             <Typography id="variant-modal-title" level="h2" textColor="inherit">
//               {props.title}
//             </Typography>
//           </div>
//           {props.children}
//         </ModalDialog>
//       </Modal>
//     </React.Fragment>
//   );
// }

import * as React from 'react';
import Chip from '@mui/joy/Chip';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TableViewIcon from '@mui/icons-material/TableView';

export default function DialogModel({ ...props }) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Chip
        variant="soft"
        startDecorator={<TableViewIcon />}
        onClick={() => setOpen(true)}
      >
        View Abilities
      </Chip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: 'custom-dialog' }}
      >
        <DialogTitle className="dialog-header">
          <p className="dialog-header-text">Ability</p>
          <CloseIcon
            onClick={() => setOpen(false)}
            className="dialog-closeicon"
          />
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

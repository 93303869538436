import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, TextField, Button, InputAdornment, Box } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Validation from './VendorsValidation';
import DeleteUser from '../../helpers/DeleteHelpers';
import SnackBar from '../../helpers/SnackBar';
import '../../style/setting_tab.css';
import '../../style/Setting.css';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
function VendorsList({ drawer }) {
  const Apiurl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [editId, setEditId] = useState(null);
  console.log('EDITID', editId);
  const [deletes, setDeletes] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackDelete, setSnackDelete] = useState(false);
  const [editSnack, setEditSnack] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  // const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrllog = process.env.REACT_APP_API_LOG;
  const API_URL = process.env.REACT_APP_API_URL;
  /**
   * Retrieves data from a data source.
   */
  const ApiurlRef = useRef(Apiurl);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Axios.get(`${API_URL}/vendors/listall`).then((res) => {
          console.log('Getting axios data', res.data);
          setUsers(res.data);
        });
      } catch (error) {
        // setError("Failed to fetch data");
        navigate('/error');
        console.error('Error occurred:', error);
        const errorData = {
          errorType: error.name,
          errorMessage: error.message,
          errorStack: error.stack,
          component: 'Vendor.js',
          functionName: 'fetchData',
        };
        await Axios.post(`${apiUrllog}/api/errors`, errorData); // Replace '/api/errors' with your backend API endpoint
        console.log('Error data sent to backend successfully');
      }
    };
    fetchData();
  }, [apiUrllog, navigate]);

  /**
   * Closes the currently open popup.
   */

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Updates the specified data with the provided changes.
   */

  const handleUpdate = async (
    id,
    businessName,
    email,
    phono,
    address,
    city,
    state,
    zip,
    businesstype,
    serviceoffered,
    website,
    contactPersonName,
    startTime,
    endTime,
    licenseType,
    licenseNumber,
    yearsInBusiness,
    filesPath
  ) => {
    const handleUpdate = {
      id,
      businessName,
      email,
      phono,
      address,
      city,
      state,
      zip,
      businesstype,
      serviceoffered,
      website,
      contact_person: contactPersonName,
      start_time: startTime,
      end_time: endTime,
      license_type: licenseType,
      license_number: licenseNumber,
      yearsin_business: yearsInBusiness,
      filesPath,
    };
    try {
      await Axios.patch(`${API_URL}/vendors/update/` + id, handleUpdate).then(
        ({ data }) => {
          console.log(data);
        }
      );
    } catch (error) {
      setError('Failed to Update data');
    }
    setEditId(null);
    setEditForm(false);
    setEditSnack(true);
  };
  const initialFormState = {
    id: null,
    businessName: '',
    email: '',
    phono: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    businesstype: '',
    serviceoffered: '',
    website: '',
    contactPersonName: '',
    startTime: '',
    endTime: '',
    licenseType: '',
    licenseNumber: '',
    yearsInBusiness: '',
    filesPath: [],
  };
  const initialDeleteFormState = {
    id: null,
    cname: '',
  };
  const [deleteCurrentUser, setDeleteCurrentUser] = useState(
    initialDeleteFormState
  );
  const [currentUser, setCurrentUser] = useState(initialFormState);
  console.log('CURRENTUSER', currentUser);

  /**
   * Edits the specified item with the provided changes.
   */

  const handleEditClick = (event, user) => {
    event.preventDefault();
    setOpen(true);
    setEditForm(true);
    setEditId(user._id);
    setCurrentUser({
      id: user._id,
      businessName: user.businessName,
      email: user.email,
      phono: user.phono,
      address: user.address,
      city: user.city,
      state: user.state,
      zip: user.zip,
      businesstype: user.businesstype,
      serviceoffered: user.serviceoffered,
      website: user.website,
      contactPersonName: user.contact_person,
      startTime: user.start_time,
      endTime: user.end_time,
      licenseNumber: user.license_number,
      licenseType: user.license_type,
      yearsInBusiness: user.yearsin_business,
      filesPath: user.filesPath,
    });
  };

  /**
   * Displays a confirmation popup to confirm deletion of an item.
   */

  const handleDelete = (id, user) => {
    setDeleteCurrentUser({
      id: user._id,
      businessName: user.businessName,
    });
    setOpen(true);
    setEditForm(false);
    setDeletes(true);
    setDeleteId(id);
  };

  /**
   * Deletes the specified item from the collection.
   */

  const handleDeletes = async (id) => {
    try {
      Axios.delete(`${API_URL}/vendors/` + id).then(({ data }) => {
        console.log(data);
      });
    } catch (error) {
      setError('Failed to delete data');
    }
    handleClose();
    setDeletes(false);
    setSnackDelete(true);
  };
  if (error) {
    // Render error fallback UI
    return <div>Error: {error}</div>;
  }
  const handleEvent = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleReset = () => {
    setSearchTerm('');
  };
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        id="grid-margin"
      >
        <Box gridColumn="span 2" gridRow="span 3">
          <div className="tab_font_style_vendors">Vendors</div>
        </Box>
        <Box gridColumn="span 4" gridRow="span 3"></Box>
        <Box gridColumn="span 4" gridRow="span 3"></Box>
        <Box gridColumn="span 2" gridRow="span 3"></Box>
        <Box gridColumn="span 2" gridRow="span 3">
          <TextField
            // label="Search..."
            id="outlined-start-adornment"
            size="small"
            onChange={handleEvent}
            placeholder="Search..."
            className="textfield-search-label"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
        <Box gridColumn="span 2" gridRow="span 3">
          {drawer ? (
            <Button
              className="button"
              id="reset-button-drawer"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          ) : (
            <Button
              className="button"
              id="reset-button-vendors"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          )}
        </Box>
        <Box gridColumn="span 8" gridRow="span 3"></Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        {editForm ? (
          <Validation
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            currentUser={currentUser}
            setEditId={setEditId}
          />
        ) : (
          <></>
        )}
        {deletes ? (
          <DeleteUser
            title={deleteCurrentUser.businessName}
            content="Are you sure to delete?"
            setOpen={setOpen}
            setDeletes={setDeletes}
            setUsers={setUsers}
            users={users}
            deleteId={deleteId}
            handleDeletes={handleDeletes}
            handleClose={handleClose}
          />
        ) : (
          <></>
        )}
      </Dialog>
      {snackDelete ? (
        <SnackBar
          title="Data deleted successfully"
          severity="error"
          vertical="top"
          horizontal="center"
        />
      ) : (
        <></>
      )}
      {editSnack ? (
        <SnackBar
          title="Data updated successfully"
          severity="success"
          vertical="top"
          horizontal="center"
        />
      ) : (
        <></>
      )}
      <TableContainer component={Paper} id="table-container">
        <Table id="table" size="small" aria-label="a dense table">
          <TableHead id="table-header">
            <TableRow>
              <TableCell id="table-header-align">Name</TableCell>
              <TableCell id="table-header-align">Email</TableCell>
              <TableCell id="table-header-align">Phone</TableCell>
              <TableCell id="table-header-align">Address</TableCell>
              <TableCell id="table-header-align">City</TableCell>
              <TableCell id="table-header-align">State</TableCell>
              <TableCell id="table-header-align">Zip</TableCell>
              <TableCell id="table-header-align">Business Type</TableCell>
              <TableCell id="table-header-align">Service Offered</TableCell>
              <TableCell id="table-header-align">Website</TableCell>
              <TableCell id="table-header-align">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users
                .filter((user) =>
                  user.businessName.toLowerCase().includes(searchTerm)
                )
                .map((user) => (
                  <Fragment>
                    <TableRow key={user._id}>
                      <TableCell>{user.businessName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.phono}</TableCell>
                      <TableCell>{user.address}</TableCell>
                      <TableCell>{user.city}</TableCell>
                      <TableCell>{user.state}</TableCell>
                      <TableCell>{user.zip}</TableCell>
                      <TableCell>{user.businesstype}</TableCell>
                      <TableCell>{user.serviceoffered}</TableCell>
                      <TableCell>{user.website}</TableCell>
                      {/* {
                          user.images.map((obj,index) => (
                               <TableRow>
                              <TableCell  key={index}>{obj}</TableCell>
                             </TableRow>
                          ))
                        } */}
                      <TableCell>
                        <EditIcon
                          className="editicon"
                          size="small"
                          onClick={(event) => handleEditClick(event, user)}
                        />
                        <DeleteIcon
                          className="deleteicon"
                          size="small"
                          onClick={() => handleDelete(user._id, user)}
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No Users</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default VendorsList;

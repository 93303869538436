import * as React from 'react';
import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import CircularLoader from '../helpers/CircularLoader';
import Axios from 'axios';

export default function ProtectedRoutes() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAuth, setIsAuth] = useState(null);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');

  async function verifyToken() {
    try {
      // Simulate API call delay for minimum 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = await Axios.get(`${API_URL}/superadmin`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      if (res.data) {
        setIsAuth(true);
        setUserName(res.data.result.username);
        setEmail(res.data.result.email);
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      console.log(err);
      setIsAuth(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await verifyToken();
    }
    fetchData();
  }, []);

  if (isAuth === null) {
    // Data is still being fetched or the loader is running for minimum 5 seconds
    return <CircularLoader />;
  } else if (isAuth) {
    // User is authenticated
    return <Outlet context={{ email }} />;
  } else {
    // User is not authenticated
    return <Navigate to="/login" />;
  }
}

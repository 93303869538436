import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UserTable from './User/UserList';
import Permission from './Permission/Permission';
import '../../style/setting_tab.css';

function Setting({ drawer }) {
  console.log('DRAWER', drawer);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="tab_box1">
        <TabContext value={value}>
          <Box className="tab_box2">
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="User" value="1" className="tab_font_style" />
              <Tab
                label="ROLE"
                value="2"
                className="tab_font_style"
                sx={{
                  marginLeft: '15px !important',
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <UserTable drawer={drawer} />
          </TabPanel>
          <TabPanel value="2">
            <Permission drawer={drawer} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
export default Setting;

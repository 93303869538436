import React from 'react';
import {
  Box,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  InputAdornment,
  InputLabel,
  FormControl,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { OutlinedInput } from '@mui/material';
import Link from '@mui/material/Link';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../../../style/Setting.css';

const AddUser = ({
  abilityList,
  validate,
  setFirstName,
  firstName,
  setLastName,
  lastName,
  setEmail,
  email,
  setCode,
  code,
  setRole,
  role,
  rolesname,
  setRolesName,
  password,
  setPassword,
  setChecked,
  checked,
  handleChange,
  user,
  errors,
  handleForm,
  handleClose,
  phono,
  setPhoNo,
}) => {
  console.log('firstName:', firstName);
  console.log('lastName:', lastName);
  console.log('email:', email);
  console.log('code:', code);
  console.log('phono:', phono);
  console.log('role:', role);
  const formLogin = (
    firstName,
    lastName,
    email,
    code,
    phono,
    role,
    password,
    checked
  ) => {
    console.log('Enter Form login');
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(firstName, lastName, email, code, phono, role).length !== 0
    )
      return handleForm(
        firstName,
        lastName,
        email,
        code,
        phono,
        role,
        password,
        checked
      );
    // props.handleClose();
  };

  const handleChanges = (event) => {
    const selectedRoleName = event.target.value;
    console.log('SELECTEDROLENAME', selectedRoleName);
    const selectedRole = abilityList.find((role) =>
      role.roleName === selectedRoleName ? role._id : null
    );
    console.log('SELECTEDROLE', selectedRole);
    setRolesName(selectedRoleName);
    setRole(selectedRole ? selectedRole._id : '');
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCancel = () => {
    handleClose();
  };
  return (
    <>
      <DialogTitle className="dialog-header">
        <p className="dialog-header-text">New User</p>
        <CloseIcon onClick={handleCancel} className="dialog-closeicon" />
      </DialogTitle>
      <DialogContent>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="10px">
          <Box gridColumn="span 6" margin="8px">
            <TextField
              fullWidth
              label="First Name"
              type="text"
              autoComplete="off"
              name="firstName"
              onChange={(e) => {
                setFirstName(e.target.value);
                validate(e, 'firstName', e.target.value);
              }}
              size="small"
            />
            {errors.firstname && <h5>{errors.firstname}</h5>}
          </Box>
          <Box gridColumn="span 6" margin="8px">
            <TextField
              fullWidth
              label="Last Name"
              onChange={(e) => {
                setLastName(e.target.value);
                validate(e, 'lastName', e.target.value);
              }}
              name="lastName"
              autoComplete="off"
              size="small"
            />
            {errors.lastname && <h5>{errors.lastname}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <FormControl fullWidth size="small">
              <InputLabel>code</InputLabel>
              <Select
                size="small"
                label="Code"
                required
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={(e) => {
                  setCode(e.target.value);
                  setPhoNo('');
                  validate(e, 'code', e.target.value);
                }}
                name="code"
              >
                <MenuItem value="+1">+1</MenuItem>
                <MenuItem value="+91">+91</MenuItem>
              </Select>
            </FormControl>
            {errors.codes && <h5>{errors.codes}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <TextField
              type="tel"
              format="(###) ###-####"
              mask="_"
              size="small"
              autoComplete="off"
              label="Contact Number"
              maxLength="10"
              onChange={handleChange}
              value={phono}
              name="phono"
            />
            {errors.contact && <h5>{errors.contact}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <TextField
              size="small"
              label="Email"
              autoComplete="off"
              onChange={(e) => {
                setEmail(e.target.value);
                validate(e, 'email', e.target.value);
              }}
              name="email"
            />
            {errors.emailcheck && <h5>{errors.emailcheck}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
                name="password"
                label="Password"
                autoComplete="off"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormControl>
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <FormControl size="small" fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                labelId="demo-select-small"
                id="demo-select-small"
                value={rolesname}
                name="role"
                onChange={handleChanges}
              >
                {abilityList.map((name) => (
                  <MenuItem key={name.roleName} value={name.roleName}>
                    {name.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.roles && <h5>{errors.roles}</h5>}
          </Box>
          <Box gridColumn="span 4" margin="8px">
            <Switch
              checked={checked}
              name="checked"
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {checked ? 'Active' : 'InActive'}
          </Box>
        </Box>

        <DialogActions>
          <Link component="button" variant="body2" onClick={handleCancel}>
            Cancel
          </Link>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ textTransform: 'none' }}
            className="submit-button"
            onClick={(e) =>
              formLogin(
                firstName,
                lastName,
                email,
                code,
                phono,
                role,
                password,
                checked
              )
            }
            disabled={
              !firstName || !lastName || !email || !code || !phono || !role
            }
          >
            Create User
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};
export default AddUser;

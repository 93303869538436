import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

export default function Alerts({ ...props }) {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.open}
        autoHideDuration={1500}
        onClose={props.onClose}
        key={props.severity}
      >
        <Alert
          variant="filled"
          onClose={props.onClose}
          severity={props.severity}
          sx={{ width: '100%' }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from './theme';

import DonationIcon from '@mui/icons-material/Paid';
import HoursIcon from '@mui/icons-material/AccessTime';
import BeneficiaryIcon from '@mui/icons-material/Diversity3';
import StatBox from './StatBox';
import Calendar from './Calendar';
import './Dashboard.css';
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box className="BoxMargin">
      <Box className="Grid">
        {/* ROW 1 */}
        <Box className="GridCard">
          <StatBox
            title="$5,000"
            subtitle="Zakat"
            progress="0.75"
            increase="+14%"
            icon={
              <DonationIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          ></StatBox>
        </Box>
        <Box className="GridCard">
          <StatBox
            title="$2,000"
            subtitle="Sadaqa"
            progress="0.50"
            increase="+21%"
            icon={
              <DonationIcon
                sx={{ color: colors.blueAccent[600], fontSize: '26px' }}
              />
            }
          ></StatBox>
        </Box>
        <Box className="GridCard">
          <StatBox
            title="40 hours"
            subtitle="Volunteered"
            progress="0.30"
            increase="+5%"
            icon={
              <HoursIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          ></StatBox>
        </Box>
        <Box className="GridCard">
          <StatBox
            title="12"
            subtitle="Beneficiaries"
            progress="0.80"
            increase="+4%"
            icon={
              <BeneficiaryIcon
                sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
              />
            }
          ></StatBox>
        </Box>

        {/* { Row 2 } */}
        <Box className="SplitRowthree">
          <Calendar />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

import React from 'react';
import './Dashboard.css';
import { Box } from '@mui/material';
const StatBox = ({ title, subtitle, icon, increase }) => {
  //const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  return (
    <Box className="Statbox">
      <Box className="BoxDisplay">
        <Box>
          {icon}
          <Box className="Displaying">{title}</Box>
        </Box>
      </Box>
      <Box className="BoxDisplay">
        <Box className="Displaying">{subtitle}</Box>
        <Box className="Displaying">{increase}</Box>
      </Box>
    </Box>
  );
};

export default StatBox;

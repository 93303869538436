import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Backdrop from '@mui/material/Backdrop';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularLoader from '../helpers/CircularLoader';

export default function LogoutDialog({ logout, setLogout, setIsLogout }) {
  const [loading, setLoading] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setLogout(false);
  };

  const navigate = useNavigate();

  async function handleSubmit() {
    setIsLogout(false); // Close the dialog immediately

    setLoading(true); // Show the circular progress bar

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulating logout delay for 1 second

      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {/* <Button onClick={handleClickOpen}>
        <Tooltip title="Logout" arrow>
          <LogoutIcon
            sx={{
              color: 'white',
              '&:hover': {
                color: 'red',
              },
            }}
          />
        </Tooltip>
      </Button> */}
      <Dialog
        open={logout}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to logout?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularLoader />
        </Backdrop>
      )}
    </div>
  );
}

import React from "react";
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Link from "@mui/material/Link";
import "../../app/App.css";
import "../../style/Pages.css";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
function EditVendors({
  handleUpdateForm,
  id,
  businessName,
  setBusinessName,
  email,
  setEmail,
  phono,
  setPhono,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  businesstype,
  setBusinessType,
  serviceoffered,
  setServiceOffered,
  website,
  setWebsite,
  contactPersonName,
  setContactPersonName,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  licenseType,
  setLicenseType,
  licenseNumber,
  setLicenseNumber,
  yearsInBusiness,
  setYearsInBusiness,
  errors,
  validate,
  handleClose,
  handleChange,
  handleFileChange,
  filesPath,
}) {
  console.log("FILES",filesPath);
  const Years = ['0-2 Years','2-5 Years','5-10 Years','Greater than 10 Years'];

  /**
   * It will passes the current value to the update function.
   */

  const handleSubmit = (
    id,
    businessName,
    email,
    phone,
    address,
    city,
    state,
    zip,
    businesstype,
    serviceoffered,
    website,
    contactPersonName,
    startTime,
    endTime,
    licenseType,
    licenseNumber,
    yearsInBusiness,
    filesPath
  ) => {
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(
        businessName,
        phono,
        address,
        city,
        state,
        zip,
        businesstype,
        serviceoffered
      ).length !== 0
    )
      return handleUpdateForm(
        id,
        businessName,
        email,
        phone,
        address,
        city,
        state,
        zip,
        businesstype,
        serviceoffered,
        website,
        contactPersonName,
        startTime,
        endTime,
        licenseType,
        licenseNumber,
        yearsInBusiness,
        filesPath
      );
  };
  console.log("FILESPATH CHECK",filesPath);

  /**
   * check the files type.
   */  
//     const filesPreview = filesPath.map((file) => {
//       if (file && file.type) {
//       const url = URL.createObjectURL(file);
//         if (file.endsWith('.jpg') || file.endsWith('.png') || file.endsWith('.jpeg')) {
//       <img src={url} alt="Preview" />
//          } else {
//       <video src={url} controls></video>
//     }
//   }
//     return null;
// });
  return (
    <>
      <DialogTitle className="dialog-title">
        <div className="grid-container">
          <div>{businessName}</div>
          <div></div>
          <div></div>
          <div className="closeicon">
            <CloseIcon onClick={() => handleClose()} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
      <div className="addform-container">
      <div>
          <TextField
            label="Contact Person"
            name="contactPersonName"
            type="text"
            value={contactPersonName}
            size="small"
            onChange={(e) => {
              setContactPersonName(e.target.value);
              validate(e, "contactPersonName", e.target.value);
            }}
          />
          {errors.contactperson && <h6>{errors.contactperson}</h6>}
        </div>
        <div>
            <TextField
              label="Phone"
              type="text"
              name="phono"
              value={phono}
              onChange={handleChange}
              size="small"
            />
            {errors.phone && <h6>{errors.phone}</h6>}
          </div>
          <div>
            <TextField
              label="Address"
              type="text"
              name="address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                validate(e, "address", e.target.value);
              }}
              size="small"
            />
            {errors.addres && <h6>{errors.addres}</h6>}
          </div>
          <div>
            <TextField
              label="City"
              type="text"
              name="city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                validate(e, "city", e.target.value);
              }}
              size="small"
            />
            {errors.citty && <h6>{errors.citty}</h6>}
          </div>
          <div>
            <TextField
              label="State"
              type="text"
              name="state"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
                validate(e, "state", e.target.value);
              }}
              size="small"
            />
            {errors.stat && <h6>{errors.stat}</h6>}
          </div>
          <div>
            <TextField
              label="Zip"
              type="text"
              name="zip"
              value={zip}
              onChange={(e) => {
                setZip(e.target.value);
                validate(e, "zip", e.target.value);
              }}
              size="small"
            />
            {errors.zipcode && <h6>{errors.zipcode}</h6>}
          </div>
          <div>
            <FormControl fullWidth>
              <InputLabel>Business Type</InputLabel>
              <Select
                size="small"
                label="Business Type"
                required
                labelId="demo-select-small"
                name="businesstype"
                value={businesstype}
                id="demo-select-small"
                onChange={(e) => {
                  setBusinessType(e.target.value);
                  validate(e, "businesstype", e.target.value);
                }}
              >
                <MenuItem value="Electrical">Electrical</MenuItem>
                <MenuItem value="Plumber">Plumber</MenuItem>
                <MenuItem value="Contractor">Contractor</MenuItem>
              </Select>
            </FormControl>
            {errors.btype && <h6>{errors.btype}</h6>}
          </div>
          <div>
            <TextField
              label="Service Offered"
              type="text"
              size="small"
              name="serviceoffered"
              value={serviceoffered}
              onChange={(e) => {
                setServiceOffered(e.target.value);
                validate(e, "serviceoffered", e.target.value);
              }}
            />
            {errors.serviceoffer && <h6>{errors.serviceoffer}</h6>}
          </div>
          <div>
            <TextField
              label="Email"
              type="text"
              name="email"
              size="small"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validate(e, "email", e.target.value);
              }}
            />
            {errors.gmail && <h6>{errors.gmail}</h6>}
          </div>
          <div>
            <TextField
              label="Website"
              type="text"
              size="small"
              name="website"
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
                validate(e, "website", e.target.value);
              }}
            />
            {errors.web && <h6>{errors.web}</h6>}
          </div>
          <div>
          <TextField
            label="Starting Time"
            name="startTime"
            type="text"
            id="time"
            value={startTime}
            size="small"
            onChange={(e) =>  setStartTime(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Closing Time"
            name="endTime"
            type="text"
            id="time"
            value={endTime}
            size="small"
            onChange={(e) =>  setEndTime(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="License Type"
            name="licenseType"
            type="text"
            value={licenseType}
            size="small"
            onChange={(e) =>  setLicenseType(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="License Number"
            name="cpname"
            type="text"
            value={licenseNumber}
            size="small"
            onChange={(e) =>  setLicenseNumber(e.target.value)}
          />
        </div>
        <div>
        <FormControl fullWidth size="small">
            <InputLabel>Years In Business</InputLabel>
            <Select
              size="small"
              label="Years In Business"
              required
              labelId="demo-select-small"
              name="yearsInBusiness"
              value={yearsInBusiness}
              id="demo-select-small"
              onChange={(e) => setYearsInBusiness(e.target.value)}
            >
              {
                Years.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
          <div>
            <input type="file" className="form-control" size="small" onChange={handleFileChange} multiple />
          </div>
          <div>
     {filesPath.map((url, index) => (
        <div key={index}>
          {url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') ? (
            <img src={url} alt="error" className="video" />
          ) : url.endsWith('.mp4') ? (
            <video src={url} controls className="video" />
          ) : (
            <p>Unknown media type: {url}</p>
          )}
        </div>
      ))}
          </div>
        </div>
        <DialogActions>
          <Link
            component="button"
            variant="body2"
            onClick={() => handleClose()}
          >
            Cancel
          </Link>
          {!businessName ||
          !phono ||
          !address ||
          !city ||
          !state ||
          !zip ||
          !businesstype ||
          !serviceoffered ? (
            <Button className="myButton">Submit</Button>
          ) : (
            <Button
              className="myadd_Button"
              onClick={() =>
                handleSubmit(
                  businessName,
                  email,
                  phono,
                  address,
                  city,
                  state,
                  zip,
                  businesstype,
                  serviceoffered,
                  website,
                  contactPersonName,
                  startTime,
                  endTime,
                  licenseType,
                  licenseNumber,
                  yearsInBusiness,
                  filesPath
                )
              }
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default EditVendors;

import React, { useMemo, memo } from 'react';
// import { styled } from '@mui/material/styles';
import { Route, Routes, Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import {
  Tooltip,
  ListItemButton,
  ListItemText,
  styled,
  ListItemIcon,
} from '@mui/material';
import { PeopleAlt, Dashboard as DashboardIcon } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Dashboard from '../pages/Dashboard/Dashboard';
import Users from '../pages/setting/Users';
import Vendors from '../pages/vendors/VendorsList';
import Logo from '../assets/homelynks-logo.jpg';
import Subscriptions from '../pages/subscription/SubscriptionList';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import '../style/Header.css';
import ServerDown from '../helpers/ServerDown';
import { useTheme } from '@emotion/react';

export default function DrawerSideBar({
  theme,
  setOpen,
  open,
  drawerWidth,
  setDrawerWidth,
  drawerLocked,
  setDrawerLocked,
  lock,
  setLock,
}) {
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [drawer, setDrawer] = React.useState(false);
  console.log('OPEN', open);

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleDrawerClose = () => {
    if (drawerLocked === false) {
      setOpen(false);
    }
  };

  const handleDrawerLock = () => {
    setDrawerLocked(!drawerLocked);
    setLock(!lock);
  };

  const handleDrawerExpand = () => {
    if (drawer === false) {
      setDrawer(!drawer);
      setDrawerWidth(190);
    } else {
      setDrawer(!drawer);
      setDrawerWidth(70);
    }
  };

  const StyledList = styled(List)(({ theme }) => ({
    padding: '10px',
    marginLeft: '-5px',
  }));

  const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    paddingTop: '2px',
    paddingBottom: '2px',
    '&.Mui-selected': {
      backgroundColor: '#3f51b5',
      color: '#fff',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: '#3f51b5',
        color: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)',
      borderRadius: '5px',
    },
  }));

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '&.MuiTypography-root': {
      textDecoration: 'none',
    },
  }));

  const StyledDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: '#3f51b5',
    width: '100%',
  }));

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        locked={drawerLocked}
        onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader className="drawer-align">
          {drawer ? (
            <img
              src={Logo}
              style={{ height: '50px', width: '100%' }}
              alt="org-img"
            />
          ) : (
            <></>
          )}
          {lock ? (
            <Tooltip title="Lockopen" placement="bottom-start" arrow>
              <LockIcon
                onClick={handleDrawerLock}
                size="small"
                className="icon1"
              />
            </Tooltip>
          ) : (
            <Tooltip title="Lock" placement="bottom-start" arrow>
              <LockOpenIcon
                size="small"
                onClick={handleDrawerLock}
                className="icon2"
              />
            </Tooltip>
          )}
          <Tooltip title="Expand" placement="right" arrow>
            <ChevronLeftIcon
              onClick={handleDrawerExpand}
              size="small"
              className="icon3"
            />
          </Tooltip>
        </DrawerHeader>
        <Divider />
        {/* <List> */}
        <StyledList component="nav" aria-label="main mailbox folders">
          <Link to="/dashboard" className="link">
            <Tooltip title="Dashboard" placement="right" arrow>
              <StyledListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  style={{ color: selectedIndex === 0 ? '#fff' : '#000' }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <StyledListItemText primary={drawer ? 'dashboard' : ''} />
              </StyledListItemButton>
            </Tooltip>
            <StyledDivider />
          </Link>
          <Divider />
          <Link to="/users" className="link">
            <Tooltip title="Users" placement="right" arrow>
              <StyledListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemIcon
                  style={{ color: selectedIndex === 1 ? '#fff' : '#000' }}
                >
                  <PeopleAlt />
                </ListItemIcon>
                <StyledListItemText primary={drawer ? 'Users' : ''} />
              </StyledListItemButton>
            </Tooltip>
            <StyledDivider />
          </Link>
          <Divider />
          <Link to="/vendors" className="link">
            <Tooltip title="Vendors" placement="right" arrow>
              <StyledListItemButton
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemIcon
                  style={{ color: selectedIndex === 2 ? '#fff' : '#000' }}
                >
                  <AssignmentIndIcon />
                  {/* {
                  drawer ? <div className="margin">Vendors</div> : <></>
                  // <ListItemText primary="Vendors" />:<></>
                } */}
                </ListItemIcon>
                <StyledListItemText primary={drawer ? 'Vendors' : ''} />
              </StyledListItemButton>
            </Tooltip>
            <StyledDivider />
          </Link>
          <Divider />
          <Link to="/subscription" className="link">
            {console.log('SUBSCRIPTION')}
            <Tooltip title="Subscription" placement="right" arrow>
              <StyledListItemButton
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemIcon
                  style={{ color: selectedIndex === 3 ? '#fff' : '#000' }}
                >
                  <LocalAtmIcon />
                </ListItemIcon>
                <StyledListItemText primary={drawer ? 'Subscription' : ''} />
              </StyledListItemButton>
            </Tooltip>
            <StyledDivider />
          </Link>
          <Divider />
          {/* </List> */}
        </StyledList>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Typography noWrap>
          <Routes>
            <Route path="/dashboard" index element={<Dashboard />} />
            <Route path="/users" element={<Users drawer={drawer} />} />
            <Route path="/vendors" element={<Vendors drawer={drawer} />} />
            <Route path="/subscription" element={<Subscriptions />} />
            <Route path="/error" element={<ServerDown />} />
          </Routes>
        </Typography>
      </Main>
    </>
  );
}

import React from 'react';
import '../style/ServerDown.css';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/server-down.jpeg';

const ServerDown = () => {
  const navigate = useNavigate();
  const handleRetry = () => {
    navigate('/');
  }
  return (
    <>
      {/* <div className='mydiv'>
      <h5>!Sorry The server is down</h5>
      <button className="retry-button" onClick={handleRetry}>
          Retry
        </button>
    </div> */}
    <div className='mydiv'>
      <img src={logo} alt='none' />
      <p className='text'>Sorry the server is down.please try after sometimes</p>
      <button className="retry-button" onClick={handleRetry}>
        Retry
      </button>
      </div>
    </>
  )
}

export default ServerDown;

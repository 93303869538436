import React from 'react';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import '../../app/App.css';
import '../../style/Pages.css';
import {
  FREE,
  STANDARD,
  PREMIUM,
  FREE_AMOUNT,
  STANDARD_AMOUNT,
  PREMIUM_AMOUNT,
  DECIMAL,
  INR_SYMBOL,
  USD_SYMBOL,
  VALID_TIMING_7DAYS,
  VALID_TIMING_30DAYS,
  VALID_TIMING_90DAYS,
  VALID_TIMING_365DAYS,
  VALID_PERIOD_7DAYS,
  VALID_PERIOD_30DAYS,
  VALID_PERIOD_90DAYS,
  VALID_PERIOD_365DAYS,
} from '../../helpers/SubscriptionConfig';
import Switch from '@mui/material/Switch';
function NewSubscription({
  handleClose,
  handleAddForm,
  errors,
  validate,
  setName,
  name,
  setPeriod,
  period,
  startDate,
  setEndDate,
  endDate,
  setAmount,
  amount,
  setFeature,
  feature,
  setChecked,
  checked,
}) {
  /**
   * Adds specified number of days to a given date.
   */
  const handleAddDays = (e) => {
    const date = new Date();
    // const future = day.setDate(day.getDate() + e);
    date.setDate(date.getDate() + e);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed

    const premium_end_date = `${year}-${month}-${day}`;
    return setEndDate(premium_end_date);
  };

  /**
   * Updates the amount based on the selected option.
   */

  const handleAmount = (e) => {
    if (e === FREE) {
      return setAmount(`${FREE_AMOUNT}${DECIMAL}`);
    } else if (e === STANDARD) {
      return setAmount(`${STANDARD_AMOUNT}${DECIMAL}`);
    } else if (e === PREMIUM) {
      return setAmount(`${PREMIUM_AMOUNT}${DECIMAL}`);
    }
  };

  /**
   * It will passes the current value to the Add function.
   */

  const handleSubmit = (
    name,
    period,
    // startDate,
    // endDate,
    feature,
    amount,
    checked
  ) => {
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(feature, checked).length !== 0
    )
      return handleAddForm(
        name,
        period,
        // startDate,
        // endDate,
        feature,
        amount,
        checked
      );
  };
  return (
    <>
      {/**
       * Updates the period based on the selected value.
       */}

      {period === VALID_TIMING_7DAYS ? (
        setPeriod(VALID_PERIOD_7DAYS)
      ) : period === VALID_TIMING_30DAYS ? (
        setPeriod(VALID_PERIOD_30DAYS)
      ) : period === VALID_TIMING_90DAYS ? (
        setPeriod(VALID_PERIOD_90DAYS)
      ) : period === VALID_TIMING_365DAYS ? (
        setPeriod(VALID_PERIOD_365DAYS)
      ) : (
        <></>
      )}
      <DialogTitle className="dialog-header">
        <p className="dialog-header-text">New Subscription</p>
        <CloseIcon onClick={() => handleClose()} className="dialog-closeicon" />
      </DialogTitle>
      <DialogContent>
        <div className="addform-container">
          <div>
            <FormControl fullWidth size="small">
              <InputLabel>Subscription</InputLabel>
              <Select
                size="small"
                label="Subscription"
                required
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={(e) => {
                  setName(e.target.value);
                  handleAmount(e.target.value);
                  validate(e, 'name', e.target.value);
                }}
                name="name"
              >
                <MenuItem value={FREE}>FREE</MenuItem>
                <MenuItem value={STANDARD}>Standard</MenuItem>
                <MenuItem value={PREMIUM}>Premium</MenuItem>
              </Select>
              {errors.subscription_type && <h6>{errors.subscription_type}</h6>}
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth size="small">
              <InputLabel>Period</InputLabel>
              <Select
                size="small"
                label="Period"
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={(e) => {
                  setPeriod(e.target.value);
                  handleAddDays(e.target.value);
                  validate(e, 'period', e.target.value);
                }}
                name="period"
              >
                <MenuItem value={VALID_TIMING_7DAYS}>Trial - 7 days</MenuItem>
                <MenuItem value={VALID_TIMING_30DAYS}>
                  Monthly - 30 days
                </MenuItem>
                <MenuItem value={VALID_TIMING_90DAYS}>
                  Quarterly - 90 days
                </MenuItem>
                <MenuItem value={VALID_TIMING_365DAYS}>Year - 1 Year</MenuItem>
              </Select>
              {errors.periods && <h6>{errors.periods}</h6>}
            </FormControl>
          </div>
          {/* <div>
            <TextField
              size="small"
              label="Start Date"
              value={startDate}
              name="startDate"
            />
          </div>
          <div>
            <TextField
              size="small"
              label="End Date"
              value={endDate}
              name="endDate"
            />
          </div> */}
          <div>
            <TextField
              label="Feature"
              type="text"
              autoComplete="off"
              name="feature"
              onChange={(e) => {
                setFeature(e.target.value);
                validate(e, 'feature', e.target.value);
              }}
              size="small"
            />
            {errors.features && <h6>{errors.features}</h6>}
          </div>
          <div>
            <TextField
              size="small"
              label={'Amount' + USD_SYMBOL}
              value={amount}
              name="amount"
            />
          </div>
          <div>
            <Switch
              checked={checked}
              name="checked"
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {checked ? 'Active' : 'Expired'}
          </div>
        </div>
        <DialogActions>
          <Link
            component="button"
            variant="body2"
            onClick={() => handleClose()}
          >
            Cancel
          </Link>
          {!name || !period || !feature ? (
            <Button className="myButton">Submit</Button>
          ) : (
            <button
              // className="myButton variantContained"
              className="myadd_Button"
              onClick={() =>
                handleSubmit(
                  name,
                  period,
                  // startDate,
                  // endDate,
                  feature,
                  amount,
                  checked
                )
              }
            >
              Submit
            </button>
          )}
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default NewSubscription;
